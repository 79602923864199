/* Base theme styles */
.dark-mode {
  background-color: #3b3b3b;
  color: #dbdbdb;
  min-height: 100vh;
}

.light-mode {
  background-color: #dbdbdb;
  color: #3b3b3b;
  min-height: 100vh;
}

/* Mode switch styles */
.mode-switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}

.mode-switch label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

/* Common styles */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 200px);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: clamp(1rem, 5vw, 3rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.content-section {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 0 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Typography */
h1,
h2,
h3 {
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: clamp(1.5rem, 4vw, 2.5rem);
}

p {
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Links */
a {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.2s ease;
}

a:hover {
  opacity: 0.8;
}

/* Responsive */
@media (max-width: 600px) {
  .page-container {
    padding: 1rem;
  }

  .content-section {
    padding: 0 1rem;
  }
}

/* Your original theme styles */
#light {
  background-color: #dbdbdb;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 5px;
  position: absolute;
  min-height: 100vh;
  overflow-x: hidden;
}

#dark {
  background-color: #3b3b3b;
  background-size: cover;
  height: 100%;
  width: 100%;
  padding: 5px;
  position: absolute;
  min-height: 100vh;
  overflow-x: hidden;
}

#light h1 {
  color: #3b3b3b;
}

#dark h1 {
  color: #dbdbdb;
}

#light .main {
  color: #3b3b3b;
}

#dark .main {
  color: #dbdbdb;
}

/* Mode switch and logo styles */
.mode-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.mode-switch label {
  margin: 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

#light .mode-switch label {
  color: #3b3b3b;
}

#dark .mode-switch label {
  color: #dbdbdb;
}

/* Logo and back arrow styles */
#chili {
  max-height: 150px;
  max-width: 150px;
  padding: 10px 8px 6px 5px;
}

.backarrow {
  max-height: 32px;
  max-width: 32px;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin-right: 0.75rem;
}

.backarrow:hover {
  transform: scale(1.1);
}

/* Add these new styles at the top */
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 1rem;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.mode-switch {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.back-arrow-container {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

#chili {
  max-height: 150px;
  max-width: 150px;
  padding: 10px 8px 6px 5px;
  transition: transform 0.2s ease;
}

#chili:hover {
  transform: scale(1.02);
}

.backarrow {
  max-height: 24px;
  max-width: 24px;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin-right: 0.5rem;
}

.backarrow:hover {
  transform: scale(1.1);
}

/* New styles for title container */
.title-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 0 1rem;
}

.title-container h2 {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Dark mode toggle styles */
.mode-switch {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.mode-switch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.mode-switch label {
  cursor: pointer;
  text-indent: -9999px;
  width: 52px;
  height: 27px;
  background: grey;
  float: right;
  border-radius: 100px;
  position: relative;
}

.mode-switch label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.mode-switch input:checked + label {
  background: #3b3b3b;
}

.mode-switch input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

/* Add these styles to prevent page overflow */
#light,
#dark {
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

/* Add specific styles for the Recently page */
.recently-container {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

/* Scrollbar styling */
.recently-container::-webkit-scrollbar {
  width: 8px;
}

.recently-container::-webkit-scrollbar-track {
  background: transparent;
}

.recently-container::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 4px;
}

/* Update the modal styles */
.MuiDialog-paper {
  background-color: transparent !important;
  box-shadow: none !important;
  width: 90% !important;
  max-width: 1000px !important;
  height: 90vh !important;
  margin: 16px !important;
}

.carousel-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.9) !important;
  border-radius: 8px;
}

.carousel-image {
  max-height: 65vh;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.carousel-caption {
  text-align: center;
  color: white;
  font-size: 0.9rem;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  display: inline-block;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

/* Update indicator styles */
.MuiMobileStepper-dots {
  position: relative !important;
  width: 100% !important;
  justify-content: center !important;
  margin-top: 1rem !important;
}
