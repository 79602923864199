.clickable {
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;
  margin-bottom: 2rem;
}

.clickable:hover {
  opacity: 0.8;
  transform: scale(1.02);
}
